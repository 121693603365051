// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-contacts-js": () => import("/opt/build/repo/src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inventory-js": () => import("/opt/build/repo/src/pages/inventory.js" /* webpackChunkName: "component---src-pages-inventory-js" */),
  "component---src-pages-invoices-js": () => import("/opt/build/repo/src/pages/invoices.js" /* webpackChunkName: "component---src-pages-invoices-js" */),
  "component---src-pages-orders-js": () => import("/opt/build/repo/src/pages/orders.js" /* webpackChunkName: "component---src-pages-orders-js" */),
  "component---src-pages-products-js": () => import("/opt/build/repo/src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

